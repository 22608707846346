<template>
  <section class="container">
    <div class="category-warp">
      <template v-if="tipsConfig[this.convertedType(type)]">
        <div>
          <div class="fz-16">
            <span>{{ tipsConfig[this.convertedType(type)].title }}</span>
          </div>
          <div class="sctp-mar-tb10">
            <el-alert
              :title="tipsConfig[this.convertedType(type)].tips" type="info"
              :closable="false" show-icon>
            </el-alert>
          </div>
        </div>
      </template>
      <div class="flex">
        <template v-if="codeTypes && codeTypes.length > 0 && this.convertedType(type) === 1">
          <div>
            <div class="mg-b10">请选择按源码类型分类</div>
            <div class="sctp-mar-b20 category-item" style="margin-right: 50px;">
              <template v-for="item in codeTypes.filter(item => item.prodtype === 1)">
                <div @click="codeType = item"
                     :class="['type-item',{ active : codeType && codeType.reqtypeid === item.reqtypeid}]">
                  {{
                    item.typename
                  }}
                </div>
              </template>
            </div>
          </div>
        </template>
        <div>
          <div class="mg-b10">请选择按源码功能分类</div>
          <div class="sctp-mar-r20 sctp-mar-b20 category-item">
            <template v-if="category">
              <template v-for="first in category.filter(item => item.type === this.convertedType(type))">
                <div @click="choose(1, first)"
                     :class="['type-item',{ active : activeCategory(1,first)}]">{{
                    first.typeName
                  }}
                </div>
              </template>
            </template>
          </div>
          <div class="sctp-mar-r20 sctp-mar-b20 category-item">
            <template v-if="category && selectedCategory[0]">
              <template v-for="second in selectedCategory[0].children">
                <div @click="choose(2, second)"
                     :class="['type-item',{ active : activeCategory(2,second)}]">
                  {{ second.typeName }}
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
      <div style="line-height: 2">
        <span>当前选择源码类型：</span>
        <template v-if="codeType">
          <span>{{ codeType.typename }}</span>
        </template>
      </div>
      <div style="line-height: 2">
        <span>当前选择分类：</span>
        <template v-for="(item,index) in selectedCategory">
          <span>{{ item.typeName }} >>> </span>
          <span v-if="index !== selectedCategory.length - 1"></span>
        </template>
      </div>
    </div>
    <div class="sctp-flex sctp-flex-center sctp-pad-tb15">
      <el-checkbox v-model="read">
        我已阅读
        <a @click.prevent="centerDialogVisible = true" class="sctp-color-main">《商品发布须知条款》</a>
        并同意
      </el-checkbox>
    </div>
    <div class="sctp-tc sctp-pad-tb20">
      <el-button @click="goPage('/shop')" size="small">返回商家中心</el-button>
      <el-button @click="onSubmitClick" size="small" type="primary">我已阅读条款，去发布商品</el-button>
    </div>
    <el-dialog
      title="商品发布须知条款"
      :visible.sync="centerDialogVisible"
      class="register-dialog"
      center>
      <div class="rich-text" v-html="productReleaseTerms"></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {common, goods} from "@/apis/index";

export default {
  components: {},
  props: {
    // 类型 1 源码类型 2 设计类型 3 文档类型
    type: {
      type: String,
      default: '',
      // validator: function (value) {
      //   return ['1', '2', '3'].indexOf(value) !== -1;
      // }
    }
  },
  data() {
    return {
      category: null,
      codeTypes: [],
      codeType: null,
      selectedCategory: [],
      read: false,
      centerDialogVisible: false,
      productReleaseTerms: null,
      tipsConfig: {
        1: {
          title: '发布源码',
          tips: '请保证出售的源代码由您本人开发或您本人具有软件著作权，任何侵权行为将有可能被追究法律责任并遭受赔偿',
        },
        2: {
          title: '发布设计原稿',
          tips: '请保证出售的设计原稿由您本人设计或制作，保证原创作品，任何侵权行为将有可能被买家起诉，用户在购买您的原稿后将有权修改设计，对您的原创作品您可以限制用户二次出售。',
        },
        3: {
          title: '发布技术文档',
          tips: '注：请保证出售的技术文档由您本人撰写，保证原创作品，任何侵权行为将有可能被买家起诉，如您希望限制用户编辑您的文档，您可把权限设置为不可编辑，并签署您的名字和增加水印。',
        }
      }
    }
  },
  methods: {
    /**
     * 获取用户协议内容
     */
    getProductReleaseTerms() {
      common.loadProtocol({
        protocolName: '商品发布需知'
      }).then(res => {
        const {retdata} = res;
        this.productReleaseTerms = retdata
      })
    },
    getCategory() {
      goods.publishPageCondition().then(res => {
        console.log("--------------------->",res)
        let {retdata, codeTypes} = res;
        this.category = retdata;
        this.codeTypes = codeTypes;
      })
    },
    choose(th, item) {
      item.th = th;
      (this.selectedCategory = this.selectedCategory.slice(0, th - 1)).push(item)
    },
    onSubmitClick() {
      if (!this.selectedCategory || this.selectedCategory.length < 2) {
        this.$message.warning('请选择分类');
        return false;
      }
      if (!this.read) {
        this.$message.warning('请确认阅读条款');
        return false;
      }
      if (this.convertedType(this.type) === 1 && !this.codeType) {
        this.$message.warning('请选择源码分类');
        return false;
      }
      let paths = {
        1: '/shop/release/sourcecode/edit',
        2: '/shop/release/design/edit',
        3: '/shop/release/techdocs/edit',
      };
      this.$router.push({
        path: paths[this.convertedType(this.type)],
        query: {
          category: JSON.stringify(this.selectedCategory),
          codeTypeId: this.codeType && this.codeType.reqtypeid
        }
      })
    },
    convertedType(type){
      switch (type){
        case "sourcecode":
          return parseInt('1');
        case "design":
          return parseInt('2');
        case "techdocs":
          return parseInt('3');
        default:
          this.goPage('/');
          return
      }
    },
  },
  computed: {
    activeCategory() {
      return (th, category) => {
        return this.selectedCategory.some(
          item => item.typeId === category.typeId && item.th === th);
      }
    }
  },
  beforeMount() {
    if ([1, 2, 3].indexOf(this.convertedType(this.type)) === -1) {
      this.goPage('/');
      return;
    }
    this.getCategory();
    this.getProductReleaseTerms()
  }
}
</script>

<style scoped lang="scss">
.container {
  background-color: white;
  padding: 15px 30px;
}

.category-warp {
  background-color: #F9F9F9;
  padding: 15px;
}

.category-item {
  width: 200px;
  border: 1px solid #dddddd;
  background-color: white;
  padding: 15px 3px;
  height: 300px;
  overflow: auto;
  display: inline-block;
}

.type-item {
  padding: 5px 0 5px 15px;
  cursor: pointer;
}

.type-item:hover {
  color: #F90;
}

.register-dialog ::v-deep .el-dialog__body {
  max-height: 50vh;
  overflow-y: auto;
}

.type-item.active {
  background-color: #F90;
  color: white;
}
</style>
